import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import SpecializeList from '@constants/webDevelopment';

// Assets
import headerImage from '@assets/services/web-dev/web-dev-header.jpg';

import AngularjsIcon from '@assets/services/web-dev/angular-js-dev.svg';
import CMSDevIcon from '@assets/services/web-dev/cms-development.svg';
import DotNetDevIcon from '@assets/services/web-dev/dotnet-dev.svg';
import ECommerceIcon from '@assets/services/web-dev/e-commerce.svg';
import LaravelDevIcon from '@assets/services/web-dev/laravel-dev.svg';
import OpenSourceDevIcon from '@assets/services/web-dev/open-source-dev.svg';
import PortalDevIcon from '@assets/services/web-dev/portal-dev.svg';
import ResponsiveDevIcon from '@assets/services/web-dev/responsive-dev.svg';
import WordpressDevIcon from '@assets/services/web-dev/wordpress-dev.svg';

const ListWrapper = styled.div``;

const ListItem = styled.div`
  padding-left: 15px;
  position: relative;
  margin: 5px 0;
  &:before {
    content: ' ';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-flex;
    position: absolute;
    top: 8px;
    left: 0;
    background-color: ${props => props.theme.colors.primary.base};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
  }
`;

const CrossPlatformWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const ElemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: center;
`;

const ItemWrapper = styled.div`
  width: 300px;
  padding: 20px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const SubHeading = styled.p`
  margin-top: 20px;
  font-weight: 600;
`;

const SubContent = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const WebDevelopment = () => {
  const iconMap = {
    rwd: <ResponsiveDevIcon />,
    dotnet: <DotNetDevIcon />,
    wordpress: <WordpressDevIcon />,
    opensource: <OpenSourceDevIcon />,
    portaldevelopment: <PortalDevIcon />,
    ecommerce: <ECommerceIcon />,
    laravel: <LaravelDevIcon />,
    angularjs: <AngularjsIcon />,
    cms: <CMSDevIcon />,
    php: <CMSDevIcon />,
    website: <CMSDevIcon />,
  };
  return (
    <Layout>
      <Helmet title={'Web Development'} />
      <PageHero image={headerImage} />
      <Heading title="Web Development" noHeadingIcon={true}>
        <p>
          As much as the app-driven market it is, websites still play a huge
          role when it comes to customer interaction and that's what most people
          tend to forget. We at CodeMyMobile provide web development
          implementations and solutions from a full-fledged website to a fully
          functioning web app.
        </p>
        <ListWrapper>
          <ListItem>
            We use the latest technologies such as PHP, Web 2.0, HTML, Joomla,
            WordPress, Drupal, Magento and OSCommerce to create responsive and
            tailor-made web applications for your business.
          </ListItem>
          <ListItem>
            We use frameworks such as ZEND, Codeigniter, Laravel, Yii, and
            CakePHP to create products that work fluidly across platforms and
            devices.
          </ListItem>
          <ListItem>
            Our unique mechanism of collaborating the oomph-ness of PHP with
            database languages such as MySQL, HTML, CSS, JavaScript is well
            demanded in the market.
          </ListItem>
        </ListWrapper>
      </Heading>
      <CrossPlatformWrapper>
        <Container>
          <SectionHeading>We specialize at</SectionHeading>
          <ElemWrapper>
            {SpecializeList.map((item, idx) => {
              return (
                <ItemWrapper key={idx}>
                  {iconMap[item.type]}
                  <SubHeading>{item.heading}</SubHeading>
                  <SubContent>{item.content}</SubContent>
                </ItemWrapper>
              );
            })}
          </ElemWrapper>
        </Container>
      </CrossPlatformWrapper>
    </Layout>
  );
};

export default WebDevelopment;
