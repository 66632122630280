const webDevelopment = [
  {
    type: 'rwd',
    heading: 'Responsive Web Development',
    content:
      'Screen sizes will always be changing, so it’s important that your ' +
      'site can adapt to any screen size, today or in the future. The use of mobile ' +
      'devices to surf the web is growing at an astronomical pace, but unfortunately, ' +
      "much of the web isn't optimized for those mobile devices. We bring in the best " +
      'designs and technologies to give the end-user a flawless and smooth cross-browser, ' +
      'cross-platform, mobile-optimized browsing.experience.',
  },
  {
    type: 'dotnet',
    heading: 'DotNet development',
    content:
      'It is an extensively used framework for developing dynamic web pages and ' +
      'websites with HTML, CSS, JavaScript and server scripting. Our DotNet developers have ' +
      'an extensive understanding of ASP.NET, c#, HTML, CSS, JavaScript, server scripting ' +
      'and MVC (Model View Controller) architecture.',
  },
  {
    type: 'wordpress',
    heading: 'WordPress development',
    content:
      'Our expert WordPress developers are skilled at building WordPress based websites, content ' +
      'management operations, all plugins, widgets and blogs. We also have expertise in customizing ' +
      'WordPress framework for various business systems.',
  },
  {
    type: 'opensource',
    heading: 'Open source web development',
    content:
      'With our open-source website overhaul and development, our clients are able to alter the source ' +
      'code for their website from its primary design to reach unattainable roll-out times and restricted funds.',
  },
  {
    type: 'portaldevelopment',
    heading: 'Portal Development',
    content:
      'We devise web portals as well as business portals. We consolidate our portals with comprehensive ' +
      'collaborative capabilities, access to aggregated data, self-service workflows and enterprise convivial ' +
      'functionality.',
  },
  {
    type: 'ecommerce',
    heading: 'E-Commerce Development',
    content:
      'Our team offers complete eCommerce resolutions with payment gateway integration, plug-in exclusive modules, ' +
      'ever-ready maintenance, and appealing UI/UX for a pleasing sense to the end-users.',
  },
  {
    type: 'laravel',
    heading: 'Laravel Development',
    content:
      'Laravel is one of the highly used, open-source modern web application frameworks that designs customized ' +
      'web applications quickly and easily.  This open-source PHP framework is slowly and steadily becoming one of ' +
      'the best web frameworks to be worked upon.',
  },
  {
    type: 'angularjs',
    heading: 'AngularJS Development',
    content:
      'AngularJS is an open-source JavaScript framework, sustained by Google. AngularJS makes it effortless to develop ' +
      'real-time applications as it has two-way data merging.',
  },
  {
    type: 'cms',
    heading: 'CMS Development',
    content:
      "You'll love how effortless it is to update, adjust, and build content on your CMS-backed website's user-friendly interface " +
      'developed by our experienced team.  The database-driven nature of a CMS also gives us the ability to uncover useful ' +
      'features for your site, like eCommerce inclinations and social networking components. ',
  },
  {
    type: 'php',
    heading: 'PHP Development',
    content:
      `PHP language that has been powering websites for over two decades. It's a popular choice for web developers due to its flexibility, ease of use, and large user community`,
  },
  {
    type: 'website',
    heading: 'Website and Web application support and maintenance',
    content:
      'We offer comprehensive support and maintenance services to ensure that your website stays up-to-date, secure, and running smoothly at all times.',
  },
];

export default webDevelopment;
